ul.videos-list li {
  list-style-type: none;
  display: inline-block;
  width: 330px;
  margin-top: 22px;
  padding: 10px;
  border-radius: 10px;
}

.popover {
  background:white
}

ul.popover-list {
  margin:0;
  padding:0;
}

ul.popover-list li {
  margin:0 0 0 10px;
  padding:0;
  list-style-type:disc;
  display:list-item;
}
