
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

//DataTable
@import "node_modules/gentelella/vendors/iCheck/skins/flat/green";

//Custom
@import "custom";


.action-buttons {
  form {
    display: inline;
  }
}
